import React from 'react';
import './Messages.css';
import Message from './Message'; 

const Messages = React.forwardRef(({ messages, token, setNumberAns, setAccuracy }, ref) => {
    return (
        <div className="messages" ref={ref}>
            {messages.map((msg, index) => (
                <Message
                    key={index}
                    text={msg.text}
                    type={msg.type}
                    conversationId={msg.conversationId} 
                    token={token}
                    setNumberAns={setNumberAns}
                    setAccuracy={setAccuracy}
                />
            ))}
        </div>
    );
});

export default Messages;