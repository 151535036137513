import React, { useState, useRef, useEffect } from 'react';
import './Home.css';
import { handleToken, sendQuestion } from './services/api.js'; 
import ChatInput from './components/Chat/ChatInput/ChatInput.js';
import Messages from './components/Messages/MessagesBox.js';
import LoadingAnimation from './components/Loading/LoadingAnimation.js';
import ModelStats from './components/Statistics/ModelStats.js';


function Home() {
    const [messages, setMessages] = useState([]);
    const [number, setNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const messagesRef = useRef(null);  
    const [token, setToken] = useState('initial_value');
    const [numberAns, setNumberAns] = useState("--");
    const [accuracy, setAccuracy] = useState(0);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const tmp = await handleToken();
                setToken(tmp);
            } catch (error) {
                console.error('API error:', error);
            }
        };

        if (token === 'initial_value') {
            fetchToken();
        }
    }, [token]);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]); 
    
    const handleMessageSubmit = async (message) => {
        if (!isLoading) {
            setIsLoading(true)
  
            try {
                // setMessages([
                //     ...messages,
                //     { text: message, type: 'question' },
                // ]);
                setMessages([
                    { text: message, type: 'question' },
                ]);
    
                const tmp = await sendQuestion(message, token, number);
                const answer = tmp.answer;
                const conversationId = tmp.conversationId;
                setNumber(number + 1)
                
                // setMessages([
                //     ...messages,
                //     { text: message, type: 'question' },
                //     { text: answer, type: 'answer', conversationId },
                // ]);
                setMessages([
                    { text: message, type: 'question' },
                    { text: answer, type: 'answer', conversationId },
                ]);
            } catch (error) {
                // setMessages([
                //     ...messages,
                //     { text: message, type: 'question' },
                //     { text: <span style={{ color: 'red' }}>Coś poszło nie tak</span>, type: 'answer' },
                // ]);
                setMessages([
                    { text: message, type: 'question' },
                    { text: <span style={{ color: 'red' }}>Coś poszło nie tak</span>, type: 'answer' },
                ]);
            }
            setIsLoading(false)
        }
    };

    return (
        <div className="App">
            {isLoading ? <LoadingAnimation /> : ""}
            <div className="left-panel">
                <div className="logo">
                    <img src="/logo_es.png" alt="Logo" className="logo" />
                </div>
                <div>
                    <ModelStats numberAns={numberAns} accuracy={accuracy} setNumberAns={setNumberAns} setAccuracy={setAccuracy} />
                    <div className="signature">Made with ❤️ by <br />Apexnet & HXS</div>
                </div>
            </div>

            <div className="right-panel">
                <div className="title">Zadaj pytanie<br />Januszowi.</div>
                <Messages messages={messages} id="messages" ref={messagesRef} token={token} setNumberAns={setNumberAns} setAccuracy={setAccuracy} />
                <div className={`chat-input ${isLoading ? 'chat-input-disabled' : ''}`}>
                    <ChatInput onMessageSubmit={handleMessageSubmit} />
                </div>
            </div>
        </div>
    );
}

export default Home;