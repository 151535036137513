import React, { useState } from 'react';
import './Messages.css';
import { updateFeedback, getNumberAns } from '../../services/api.js'; 

const Message = ({ text, type, conversationId, token, setNumberAns, setAccuracy }) => {
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);
    const [showFeedback, setShowFeedback] = useState(true);
    const [feedback, setFeedback] = useState('');

    const fetchNumberAns = async () => {
        try {
            const result = await getNumberAns();
            setNumberAns(result.numberAns);
            setAccuracy(result.accuracy);
        } catch (error) {
            setNumberAns("--");
        }
    }

    fetchNumberAns();

    const handleThumbsUpClick = async () => {
        setShowFeedback(false)
        await updateFeedback(conversationId, true, "", token)
        setShowFeedbackInput(false)
    };

    const handleThumbsDownClick = async () => {
        setShowFeedbackInput(true);
        await updateFeedback(conversationId, false, "", token)
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleFeedbackSubmit = async () => {
        await updateFeedback(conversationId, false, feedback, token)
        setShowFeedbackInput(false)
        setShowFeedback(false)
    };

    return (
        <div className={`message ${type}`}>
            <p>{text}</p>
            {type === 'answer' && showFeedback && (
                <div className="feedback-buttons">
                    <button className="thumbs-up" onClick={handleThumbsUpClick}>👍</button>
                    <button className="thumbs-down" onClick={handleThumbsDownClick}>👎</button>
                </div>
            )}
            {showFeedbackInput && (
                <div>
                    <input
                        className='feedback-input'
                        type="text"
                        placeholder="Podaj poprawną odpowiedź."
                        value={feedback}
                        onChange={handleFeedbackChange}
                    />
                    <button className='feedback-input-button' onClick={handleFeedbackSubmit}>Wyślij feedback</button>
                </div>
            )}
        </div>
    );
};

export default Message;
