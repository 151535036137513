import axios from 'axios';

const API_URL = 'https://bot-api.edustrefa.pl';
// const API_URL = 'http://127.0.0.1:5001';

export const sendQuestion = async (question, token, number) => {
    try {
    const response = await axios.post(`${API_URL}/conversations`, 
    {
        question,
        token,
        number
    });
    return response.data;
    } catch (error) {
    console.error('API error:', error);
    throw error;
    }
};

export const updateFeedback = async (conversationId, vote, feedback, token) => {
    try {
        const response = await axios.put(`${API_URL}/conversations/` + conversationId.toString() + `/feedback`, 
        {
            vote,
            feedback,
            token
        });
        return response.data;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

export const handleToken = async () => {
    const response = await axios.post(`${API_URL}/login`);
    return response.data.token;
}

export const getNumberAns = async () => {
    try {
        const response = await axios.get(`${API_URL}/stats`);
        return response.data;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}