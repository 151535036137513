import React, { Component } from 'react';

class ChatInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    handleMessageChange = (event) => {
        this.setState({ message: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { message } = this.state;
        this.props.onMessageSubmit(message);
        this.setState({ message: '' });
    }

    render() {
        const { message } = this.state;

        const inputStyles = {
            padding: '10px',
            fontSize: '16px',
            border: '0px solid #ccc',
            borderRadius: '0px',
            width: '79%',
            boxSizing: 'border-box',
            fontWeight: 'bold',
            height: '40px'
        };

        const buttonStyles = {
            marginLeft: '1%',
            width: '20%',
            marginTop: '10px',
            padding: '8px 12px',
            fontSize: '14px',
            backgroundColor: '#D31773',
            color: '#000',
            border: 'none',
            borderRadius: '0px',
            cursor: 'pointer',
            fontWeight: 'bold',
            height: '40px'
        };

        return (
        <form onSubmit={this.handleSubmit}>
            <input
            type="text"
            placeholder="Zadaj pytanie..."
            value={message}
            onChange={this.handleMessageChange}
            style={inputStyles}
            />
            <button type="submit" style={buttonStyles}>Wyślij</button>
        </form>
        );
    }
}

export default ChatInput;
