import React, { useEffect } from 'react';
import './ModelStats.css';
import { getNumberAns } from '../../services/api.js'; 



const ModelStats = ({ numberAns, accuracy, setNumberAns, setAccuracy }) => {
    useEffect(() => {
        const fetchNumberAns = async () => {
            try {
                const result = await getNumberAns();
                setNumberAns(result.numberAns);
                setAccuracy(result.accuracy)
            } catch (error) {
                setNumberAns("--");
            }
        }

        fetchNumberAns();
    }, [setNumberAns]);

    return (
        <div className='statBox'>
            <p className='statTitle'>Liczba pytań:</p>
            <p className='statNum'>{numberAns}</p>
            <p className='statTitle'>Dokładność:</p>
            <div className='progressBox'>
                <div className='progress' style={{ width: accuracy + '%' }}></div>
            </div>
        </div>
    );
}

export default ModelStats;
