import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    position: absolute;
    border: 3px solid white;
    right: 4px;
    top: 4px;
    border-top: 3px solid #333;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${rotate} 1s linear infinite;
    margin: auto;
`;

const LoadingAnimation = () => {
    return (
        <Spinner />
    );
};

export default LoadingAnimation;